@font-face {
    font-family:'HN';
    src: url('../fonts/BMHANNA_11yrs_otf.otf') format('opentype');
  }

  @font-face {
    font-family:'DO';
    src: url('../fonts/BMDOHYEON_otf.otf') format('opentype');

  }
  
  @font-face {
    font-family:'YS';
    src: url('../fonts/BMYEONSUNG_otf.otf') format('opentype');
  }

  @font-face {
    font-family:'BMJUA';
    src: url('../fonts/BMJUA_otf.otf') format('opentype');
  }

  @font-face {
    font-family:'A';
    src: url('../fonts/BMHANNAAir_otf.otf') format('opentype');
  }



@font-face {
  font-family: 'NanumSquareNeo-Variable';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_11-01@1.0/NanumSquareNeo-Variable.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BMDOHYEON';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_one@1.0/BMDOHYEON.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'BMDOHYEON1';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_one@1.0/BMDOHYEON.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'MICE';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2206-01@1.0/MICEGothic Bold.woff2') format('woff2');
  font-weight:400;
  font-style: normal;
}

